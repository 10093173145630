._1yk3a450 {
  min-height: var(--stackflow-plugin-basic-ui-app-bar-min-height);
}
._1yk3a451 {
  overflow: var(--stackflow-plugin-basic-ui-app-bar-overflow);
}
._1yk3a452 {
  background-color: var(--stackflow-plugin-basic-ui-app-bar-background-color);
  z-index: var(--_1dlf4344);
  transition: background-color var(--stackflow-plugin-basic-ui-app-bar-background-color-transition-duration), box-shadow var(--stackflow-plugin-basic-ui-app-bar-border-color-transition-duration);
}
._1q6dj2j1 ._1yk3a452 {
  position: absolute;
}
._1q6dj2j1 ._1dlf434b ._1yk3a452 {
  transform: translate3d(100%, 0, 0);
  transition: background-color var(--stackflow-plugin-basic-ui-app-bar-background-color-transition-duration), box-shadow var(--stackflow-plugin-basic-ui-app-bar-border-color-transition-duration), transform 0s;
}
._1q6dj2j0 ._1yk3a452 {
  opacity: 0;
  transform: translate3d(0, 10rem, 0);
  transition: background-color var(--stackflow-plugin-basic-ui-app-bar-background-color-transition-duration), box-shadow var(--stackflow-plugin-basic-ui-app-bar-border-color-transition-duration), transform var(--_1dlf4340), opacity var(--_1dlf4340);
}

        ._1q6dj2j0 ._1dlf4349 ._1yk3a452,
        ._1q6dj2j0 ._1dlf434a ._1yk3a452
       {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
._1yk3a453 {
  box-shadow: inset 0px calc(-1 * var(--stackflow-plugin-basic-ui-app-bar-border-size)) 0 var(--stackflow-plugin-basic-ui-app-bar-border-color);
}
._1q6dj2j1 ._1yk3a454 {
  transform: translate3d(0, 100vh, 0);
  transition: background-color var(--stackflow-plugin-basic-ui-app-bar-background-color-transition-duration), box-shadow var(--stackflow-plugin-basic-ui-app-bar-border-color-transition-duration), transform var(--_1dlf4340), opacity var(--_1dlf4340);
}

          ._1q6dj2j1 ._1dlf4349 ._1yk3a454,
          ._1q6dj2j1 ._1dlf434a ._1yk3a454
         {
  transform: translate3d(0, 0, 0);
}
._1q6dj2j1 ._1dlf434b ._1yk3a454 {
  transform: translate3d(0, 100vh, 0);
  transition: background-color var(--stackflow-plugin-basic-ui-app-bar-background-color-transition-duration), box-shadow var(--stackflow-plugin-basic-ui-app-bar-border-color-transition-duration), transform var(--_1dlf4340), opacity var(--_1dlf4340);
}
._1q6dj2j0 ._1yk3a455 {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
._1q6dj2j0 ._1dlf434b ._1yk3a455 {
  transform: translate3d(100%, 0, 0);
  transition: background-color var(--stackflow-plugin-basic-ui-app-bar-background-color-transition-duration), box-shadow var(--stackflow-plugin-basic-ui-app-bar-border-color-transition-duration), transform 0s;
}
._1yk3a456 {
  height: max(var(--stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top), constant(safe-area-inset-top));
  height: max(var(--stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top), env(safe-area-inset-top));
}
._1yk3a457 {
  height: var(--stackflow-plugin-basic-ui-app-bar-height);
  transition: height var(--stackflow-plugin-basic-ui-app-bar-height-transition-duration);
}
._1yk3a458 {
  padding: 0 0.5rem;
}
._1yk3a458:empty {
  display: none;
}
._1yk3a459 {
  color: var(--stackflow-plugin-basic-ui-app-bar-icon-color);
  transition: opacity 300ms, color var(--stackflow-plugin-basic-ui-app-bar-icon-color-transition-duration);
  width: 2.25rem;
  height: 2.75rem;
}
._1yk3a459:active {
  opacity: 0.2;
  transition: opacity 0s, color var(--stackflow-plugin-basic-ui-app-bar-icon-color-transition-duration);
}
._1yk3a45c {
  width: var(--_1dlf4345);
  color: var(--stackflow-plugin-basic-ui-app-bar-text-color);
  transition: height var(--stackflow-plugin-basic-ui-app-bar-height-transition-duration), color var(--stackflow-plugin-basic-ui-app-bar-text-color-transition-duration);
}
._1q6dj2j0 ._1yk3a45c {
  width: 100%;
  justify-content: flex-start;
  padding-left: 1rem;
  font-size: 1.1875rem;
  line-height: 1.5;
  font-weight: bold;
  box-sizing: border-box;
}
._1q6dj2j1 ._1yk3a45c {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 600;
  font-size: 1rem;
  left: 50%;
  transform: translate(-50%);
  height: var(--stackflow-plugin-basic-ui-app-bar-height);
  top: max(var(--stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top), constant(safe-area-inset-top));
  top: max(var(--stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top), env(safe-area-inset-top));
}
._1q6dj2j0 ._1yk3a45d {
  padding-left: 0.375rem;
}
._1yk3a45e {
  left: 50%;
  height: 1.25rem;
  transform: translate(-50%);
  max-width: 5rem;
  display: none;
  width: var(--_1dlf4345);
}
._1q6dj2j1 ._1yk3a45e {
  display: block;
}
._1yk3a45f {
  text-overflow: ellipsis;
  font-size: inherit;
  font-weight: inherit;
}
._1yk3a45g {
  padding: 0 0.5rem;
  margin-left: auto;
}
._1yk3a45g:empty {
  display: none;
}
._1q6dj2j0 ._1yk3a45g {
  padding: 0 0.5rem 0 0;
}